<template>
    <div>
        <div class="mt-8">
            <div class="flex font-semibold justify-between py-3 text-sm border-t">
                <span class="uppercase">Acompte</span>
                <span>{{ acompte + ' €' }}</span>
            </div>

            <div class="flex font-semibold justify-between py-3 text-base border-t">
                <span class="uppercase">Total</span>
                <span>{{ total }} €</span>
            </div>

            <TermsConditionCheckbox
                v-on:termsConditionAcceptedEvent="termsConditionAcceptedEmit"
                v-on:termsConditionShowPopupEvent="termsConditionShowPopupEmit"
            />

            <vs-button
                @click="processPayment()"
                :disabled="submitBtnPayIsActive"
                class="w-full flex justify-center rounded-full p-3 mt-4 hover:opacity-75 tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500"
            >
                <svg xmlns="http://www.w3.org/2000/svg" class="inline h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                {{ paymentProcessing ? 'Traitement' : 'Payer '+ acompte + ' €' }}
            </vs-button>
        </div>
    </div>
</template>

<script>
    import { loadStripe } from '@stripe/stripe-js';
    const TermsConditionCheckbox = () => import("@/views/welcome-page/reservation/TermsConditionCheckbox.vue");

    export default {
        props: {
            acompte: {
                required: true
            },
            total: {
                required: true
            }
        },
        data() {
            return {
                stripe: {},
                error: '',
                isTermsConditionAccepted: false,
            }
        },
        async mounted() {
            this.stripe = await loadStripe(this.stripeKey);
        },
        methods: {
            async processPayment() {
                this.$store.commit('eCommerce/TOGGLE_LOADING_PAY_STRIPE', true)
                this.$emit('payWithStripeCheckoutEvent', this.stripe)
            },
            termsConditionAcceptedEmit(payload) {
                this.$emit('termsConditionAcceptedEventStripe', payload)
                this.isTermsConditionAccepted = payload
            },
            termsConditionShowPopupEmit() {
                console.log('termsConditionShowPopupEmit')
                this.$emit('termsConditionShowPopupEventStripe')
            },
        },
        components: {
            TermsConditionCheckbox
        },
        computed: {
            paymentProcessing () {
                return this.$store.state.eCommerce.paymentProcessing
            },
            stripeKey(){
                return this.$store.state.moduleSetting.customerSetting ? this.$store.state.moduleSetting.customerSetting.STRIPE_KEY : ''
            },
            submitBtnPayIsActive() {
                return this.total==0||!this.isTermsConditionAccepted||this.paymentProcessing?true:false
            }
        },
    }
</script>